import { createApp } from "vue";
require('./bootstrap');
import Vue from 'vue';
window.Vue = require('vue').default;
window.$ = require('jquery')

import VueTheMask from "vue-the-mask";
import money from 'v-money'
import VueSpinners from 'vue-spinners'
import UpsertPackage from './components/UpsertPackage.vue';
import InputMoney from "./components/InputMoney.vue";
import InputNumber from "./components/InputNumber.vue";
import PackageServices from "./components/PackageServices.vue";
import SelectService from "./components/SelectService.vue";
import UpdatePetPackage from "./components/UpdatePetPackage.vue";

import { Calendar } from "@fullcalendar/core";

const app = createApp()

app.component('VueSpinners', VueSpinners)
    .component('VueTheMask', VueTheMask)
    .component('money', money)
    .component('InputNumber', InputNumber)
    .component('InputMoney', InputMoney)
    .component('SelectService', SelectService)
    .component('PackageServices', PackageServices)
    .component('UpdatePetPackage', UpdatePetPackage)
    .component('UpsertPackage', UpsertPackage);

app.mount('#app');

require('admin-lte/plugins/jquery-ui/jquery-ui.min.js')
require('admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js')
require('admin-lte/plugins/chart.js/Chart.min.js')
// require('admin-lte/plugins/sparklines/sparkline.js')
require('admin-lte/plugins/jqvmap/jquery.vmap.min.js')
require('admin-lte/plugins/jqvmap/maps/jquery.vmap.usa.js')
require('admin-lte/plugins/jquery-knob/jquery.knob.min.js')
require('admin-lte/plugins/daterangepicker/daterangepicker.js')
require('admin-lte/plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4.min.js')
require('admin-lte/plugins/summernote/summernote-bs4.min.js')
require('admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js')
require('admin-lte/plugins/select2/js/select2.full.min.js')
require('admin-lte/plugins/bootstrap4-duallistbox/jquery.bootstrap-duallistbox.min.js')
require('admin-lte/plugins/bootstrap-colorpicker/js/bootstrap-colorpicker.min.js')
require('admin-lte/plugins/bootstrap-switch/js/bootstrap-switch.min.js')
require('admin-lte/dist/js/adminlte.min.js')
// require('admin-lte/dist/js/pages/dashboard.js')
require('admin-lte/dist/js/demo.js')
require('admin-lte/plugins/inputmask/jquery.inputmask.min.js')
require('admin-lte/plugins/fullcalendar/main.js')
require('admin-lte/plugins/fullcalendar-daygrid/main.min.js')
require('admin-lte/plugins/fullcalendar-timegrid/main.min.js')
require('admin-lte/plugins/fullcalendar-interaction/main.min.js')
require('admin-lte/plugins/fullcalendar-bootstrap/main.min.js')
require('admin-lte/plugins/flot/jquery.flot.js')

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

window.FullCalendar = Calendar
window.dayGridPlugin = dayGridPlugin
window.timeGridPlugin = timeGridPlugin
window.interactionPlugin = interactionPlugin

$('.sidebar-toggle').on('click', function () {
    var cls = $('body').hasClass('sidebar-collapse');
    if (cls == true) {
        $('body').removeClass('sidebar-collapse');
    } else {
        $('body').addClass('sidebar-collapse');
    }
});

$.widget.bridge('uibutton', $.ui.button)
$(function () {
    $.fn.modal.Constructor.prototype._enforceFocus = function () { };
    $("#client").select2({
        dropdownParent: $("#modal-container")
    });

    $('.select2').select2({})
    $('.cpf-mask').inputmask('999.999.999-99')
    $('.crmv-mask').inputmask('99.999', {
        removeMaskOnSubmit: true,
        autoUnmask: true,
    })
    $('.time-mask').inputmask('999')
    $('.number-mask').inputmask('999')
    $('.cnpj-mask').inputmask('99.999.999/9999-99')
    $('.zip-code-mask').inputmask('99999-999', {
        removeMaskOnSubmit: true,
        autoUnmask: true,
    })
    $('.date-mask').inputmask('99/99/9999')
    $('.date-hour-mask').inputmask('99/99/9999 99:99')
    $('.date-service-mask').inputmask('99/99/9999 99:99')
    $('.comission-mask').inputmask('99%', {
        removeMaskOnSubmit: true,
        autoUnmask: true,
    })
    $('.money-mask').inputmask('currency', {
        autoUnmask: true,
        radixPoint: ",",
        groupSeparator: ".",
        allowMinus: false,
        prefix: 'R$ ',
        digits: 2,
        digitsOptional: false,
        rightAlign: false,
        unmaskAsNumber: true
    })
    $('.value-mask').inputmask('currency', {
        autoUnmask: true,
        radixPoint: ",",
        groupSeparator: ".",
        allowMinus: false,
        digits: 2,
        digitsOptional: false,
        rightAlign: false,
        unmaskAsNumber: true
    })
    $('.percentage-mask').inputmask({
        alias: 'percentage',
        autoUnmask: true,
        radixPoint: ",",
        groupSeparator: ".",
        allowMinus: false,
        digits: 2,
        digitsOptional: false,
        rightAlign: false,
        unmaskAsNumber: true,
        removeMaskOnSubmit: true,
    })
    $('.phone-mask').inputmask('(99) 9999-9999', {
        removeMaskOnSubmit: true,
        autoUnmask: true,
    })
    $('.weight-mask').inputmask('9.999', {
        removeMaskOnSubmit: false,
        autoUnmask: true,
    })
    $('.cellphone-mask').inputmask('(99) 99999-9999', {
        removeMaskOnSubmit: true,
        autoUnmask: true,
    })
    $('.address-number-mask').inputmask('999999999999', {
        removeMaskOnSubmit: true,
        autoUnmask: true,
    })
    $('.quantity-mask').inputmask('99999')
    $('.age-mask').inputmask('99')
    $('.pet-weight-mask').inputmask('currency', {
        autoUnmask: true,
        groupSeparator: ".",
        allowMinus: false,
        digits: 2,
        digitsOptional: false,
        rightAlign: false,
        unmaskAsNumber: true
    })

    $('.delete-record').click(function () {
        $('#modal-danger .message-body').text($(this).attr('data-message-body'))
        $('#modal-danger .route-remove').attr('href', $(this).attr('data-route-remove'))
        $('#modal-danger').modal({ backdrop: false })
        return false
    })

    $('.issue-invoice').on('click', function () {
        var action = $(this).attr('data-action');
        if (action == "paid") {
            $('#modal-invoice').modal({ backdrop: false })
        } else {
            var id = $(this).attr('data-id');
            if (action == "bulk_pay") {
                $('#modal-invoice-t' + id).modal({ backdrop: false })
            } else {
                $('#modal-invoice-' + id).modal({ backdrop: false })
            }
        }
        return false
    })

    $('.select2-container--default').removeAttr('style')

    if ($('form select[name="role_id"]').val() != 5) {
        $('.user-consumer').show();
    } else {
        $('.user-consumer').hide();
    }

    $('form select[name="role_id"]').change(function () {
        const roleId = $(this).val()

        if (roleId != 5) {
            $('.user-consumer').show();
        } else {
            $('.user-consumer').hide();
        }

    })

    $(".check-all").on("click", function () {
        $('input:checkbox').not(this).prop('checked', this.checked);
    })

    $('.find-zip-code').on("change", function () {

    });

    function addPetAdditionalFields(type) {
        if (type === 'controlled') {
            $('.prescription-controlled-content').show();
        } else {
            $('.prescription-controlled-content').hide();
        }
    }
    let currentValue = $('select[name="prescription_type"]').val();
    addPetAdditionalFields(currentValue);

    $('select[name="prescription_type"]').change(function () {
        let value = $(this).find(':selected').val()
        addPetAdditionalFields(value);
    });

    $('.text-editor').summernote({
        toolbar: [],
        height: 100,
    });

    $('input[name="zip_code"]').keyup(function () {
        if ($(this).val().length < 8) return;

        let zipCode = $(this).val();
        let route = $('.zip-code-route').val();

        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            url: route,
            type: 'GET',
            data: {
                'zip_code': zipCode,
            },
            dataType: 'json',
            beforeSend: function () {
                $('.loader').fadeIn();
            },
            complete: function () {
                $('.loader').fadeOut();
            },
            success: function (data) {
                if (data.logradouro === null) {
                    alert('Não foi possivel recuperar dados do CEP informado')
                    return
                }
                $('input[name="address"]').val(data.logradouro)

                $('input[name="state"]').val(data.uf);
                $('select[name="state"]').val(data.uf);
                $('select[name="state"]').select2().change();

                $('input[name="city"]').val(data.localidade);
                $('select[name="city"]').val(data.localidade);
                $('select[name="city"]').select2().change();

                $('input[name="neighborhood"]').val(data.bairro)
                $('input[name="complement"]').val(data.complemento)
            },
            error: function () {
                alert('CEP não existe')
                $('input[name="address"]').val('')

                $('input[name="state"]').val('');
                $('select[name="state"]').val('');
                $('select[name="state"]').select2().change();

                $('input[name="city"]').val('');
                $('select[name="city"]').val('');
                $('select[name="city"]').select2().change();

                $('input[name="neighborhood"]').val('')
                $('input[name="complement"]').val('')
            }
        });
        return false;
    });

    $('input[name="cnpj"]').keyup(function () {
        if ($(this).val().length < 17) return;

        let cnpj = $(this).val();
        let route = $('.cnpj-route').val();

        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            url: route,
            type: 'POST',
            data: {
                'cnpj': cnpj,
            },
            dataType: 'json',
            beforeSend: function () {
                $('.loader').fadeIn();
            },
            complete: function () {
                $('.loader').fadeOut();
            },
            success: function (data) {
                if (data.status == 'ERROR') {
                    alert('CNPJ inválido')
                    $('input[name="zip_code"]').val('');
                    $('input[name="address"]').val('');
                    $('input[name="address_number"]').val('');
                    $('input[name="city"]').val('');

                    $('input[name="state"]').val('');
                    $('select[name="state"]').val('');
                    $('select[name="state"]').select2().change();

                    $('input[name="company_name"]').val('');
                    $('input[name="fantasy_name"]').val('');
                    $('input[name="phone"]').val('');
                    $('input[name="neighborhood"]').val('');
                    $('input[name="complement"]').val('');
                    $('input[name="email"]').val('');
                    return
                }
                $('input[name="zip_code"]').val(data.cep);
                $('input[name="address"]').val(data.logradouro);
                $('input[name="address_number"]').val(data.numero);
                $('input[name="city"]').val(data.municipio);

                $('input[name="state"]').val(data.uf);
                $('select[name="state"]').val(data.uf);
                $('select[name="state"]').select2().change();

                $('input[name="company_name"]').val(data.nome);
                $('input[name="fantasy_name"]').val(data.fantasia);
                $('input[name="phone"]').val(data.telefone);
                $('input[name="neighborhood"]').val(data.bairro);
                $('input[name="complement"]').val(data.complemento);
                $('input[name="email"]').val(data.email);
            },
            error: function () {
                alert('CEP não existe')
                $('input[name="zip_code"]').val('');
                $('input[name="address"]').val('');
                $('input[name="address_number"]').val('');
                $('input[name="city"]').val('');
                $('input[name="state"]').val('');
                $('input[name="company_name"]').val('');
                $('input[name="fantasy_name"]').val('');
                $('input[name="phone"]').val('');
                $('input[name="neighborhood"]').val('');
                $('input[name="complement"]').val('');
                $('input[name="email"]').val('');
            }
        });
        return false;
    });

    if ($('input[name="is_add_prescription"').is(":checked")) {
        $('.prescription-content').removeClass('d-none');
    } else {
        $('.prescription-content').addClass('d-none');
    }

    $('input[name="is_add_prescription"').change(function() {
        let value = $(this).is(":checked")
        if (value) {
            $('.prescription-content').removeClass('d-none');
        } else {
            $('.prescription-content').addClass('d-none');
        }
    })

    if ($('input[name="is_add_pet_exams"').is(":checked")) {
        $('.pet-exams-content').removeClass('d-none');
    } else {
        $('.pet-exams-content').addClass('d-none');
    }

    $('input[name="is_add_pet_exams"').change(function() {
        let value = $(this).is(":checked")
        if (value) {
            $('.pet-exams-content').removeClass('d-none');
        } else {
            $('.pet-exams-content').addClass('d-none');
        }
    })
});

$(window).on('load', function () {
    $('.loader').fadeOut();
});
